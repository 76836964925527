<template>
  <v-card tile flat class="transparent" height="100%" width="100%">  
      
    <v-list v-if="!Refreshing">
        <v-list-item>
            <v-text-field return-object v-model="GroupSearch" label="Group Search"/>
        </v-list-item>
       
       <v-virtual-scroll 
            width="100%"
            class="transparent"
            :items="ComputedMonetizationGroups"
            :item-height="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs ? 100 : 150"
            :height="ScrollHeight"
            >
            <template v-slot:default="{ item }"> 
        <v-list-item class="detailslistoutline">
            <v-list-item-avatar tile>
                <img style="object-fit: cover;" :src="item.logo"/>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    {{item.name}}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{item.MonetizationPackage.Name}}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                <v-chip-group column>
                    <v-chip x-small :disabled="!item[lvl.FieldName]" @click="ActivateCatFilter(lvl,item[lvl.FieldName])" v-for="lvl in GroupLevelNames" :key="lvl.itemObjKey">
                        {{item[lvl.FieldName].Name}}
                    </v-chip>
                </v-chip-group>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
                 <v-list-item-title class="justify-start">
                    Listing
                </v-list-item-title>
                <v-list-item-subtitle>
                   {{CurrencyFormatter(item.MonetizationPackage.Price,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-subtitle>
            </v-list-item-content>
                <v-tooltip v-if="GroupKeywordsPackage && item.KeyWordsToolip"
                    top max-width="300"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item-content v-bind="attrs"
                        v-on="on" v-if="GroupKeywordsPackage">
                        <v-list-item-title>
                            Keywords
                        </v-list-item-title>
                        <v-list-item-subtitle>
                        {{CurrencyFormatter(item.KeywordsPrice,$store.state.DefaultCurrency.Currency)}}
                        </v-list-item-subtitle> 
                        </v-list-item-content>
                    </template>
                    <span v-html="item.KeyWordsToolip"> 
                    </span>
                </v-tooltip>
                        <v-list-item-content v-if="GroupKeywordsPackage && !item.KeyWordsToolip">
                        <v-list-item-title>
                            Keywords
                        </v-list-item-title>
                        <v-list-item-subtitle>
                        {{CurrencyFormatter(item.KeywordsPrice,$store.state.DefaultCurrency.Currency)}}
                        </v-list-item-subtitle> 
                        </v-list-item-content>
            <v-list-item-action>
                <v-btn x-small :to="'/Group/'+item.id">
                View
                </v-btn>
            </v-list-item-action>
        </v-list-item>
            </template>
       </v-virtual-scroll>
    </v-list>
    <v-card-title class="mediumoverline">
          Paid Groups
          <v-spacer>
          </v-spacer>
          {{ComputedMonetizationGroups.length}}
      </v-card-title>
      <v-card-title class="mediumoverline">
          Monthly
          <v-spacer>
          </v-spacer>
          {{CurrencyFormatter(MonthlyPackagesTotal,$store.state.DefaultCurrency.Currency)}}
      </v-card-title>
      <v-card-title class="mediumoverline">
          Annual
          <v-spacer>
          </v-spacer>
          {{CurrencyFormatter(AnnualPackagesTotal,$store.state.DefaultCurrency.Currency)}}
      </v-card-title>
      <v-card-title class="largeoverline">
          Year Total
          <v-spacer>
          </v-spacer>
          {{CurrencyFormatter(PackagesTotal,$store.state.DefaultCurrency.Currency)}}
      </v-card-title>
       <v-list-item class="justify-end">
        <v-btn dark color="success" @click="RefreshView()">Refresh</v-btn>
        </v-list-item>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
            Refreshing: false,
            GroupSearch: '',
            LevelFilter: '',
            LevelFilterValue: '',
        }
    },
    computed:{
        ScrollHeight(){
            let length = this.ComputedMonetizationGroups.length
            if(length > 4){
                if(!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs){
                    return this.WindowHeight*0.43
                }
                else{
                    return this.WindowHeight*0.43
                }
            }
            else{
              return (this.WindowHeight*0.43)/(4/length)  
            }
        },
        WindowHeight(){
          return window.innerHeight
      },
        GroupKeywordsPaid(){
            return this.System.Group_Keywords_Paid
        },
        GroupKeywordsPackage(){
            return this.System.Group_Keywords_Order_Qty && this.System.Group_Keywords_Order_Price ? {
                KeywordOrderQty: this.System.Group_Keywords_Order_Qty,
                KeywordOrderPrice: this.System.Group_Keywords_Order_Price
            } : ''
        },
        GroupLevelNames(){
            return this.System.Group_Categories.Levels ? this.System.Group_Categories.Levels : []
        },
        GroupCategoryTypes() {
        if (
            this.System &&
            this.System.Group_Categories &&
            this.System.Group_Categories.Options
        ) {
            return this.System.Group_Categories.Options;
        } else {
            return [];
        }
        },
        AnnualPackagesTotal(){
            return this.ComputedMonetizationGroups.filter(group => {
                return group.MonetizationPackage.PaymentSchedule === 'Annual'
            }).filter(group => {
                let price = group.MonetizationPackage.Price
                if(this.GroupKeywordsPackage){
                    price = price+(group.KeywordsPrice*12)
                }
                return price
            }).map(group => {
                return group.MonetizationPackage.Price
            }).reduce((a, b) => a + b, 0)
        },
        MonthlyPackagesTotal(){
            return this.ComputedMonetizationGroups.filter(group => {
                return group.MonetizationPackage.PaymentSchedule === 'Monthly'
            }).filter(group => {
                return group.MonetizationPackage.Price
            }).map(group => {
                let price = group.MonetizationPackage.Price
                if(this.GroupKeywordsPackage){
                    price = price+group.KeywordsPrice
                }
                return price
            }).reduce((a, b) => a + b, 0)
        },
        PackagesTotal(){
            return this.ComputedMonetizationGroups.filter(group => {
                return group.MonetizationPackage.Price
            }).map(group => {
                let annualtotal = group.MonetizationPackage.Price
                 if(this.GroupKeywordsPackage){
                    annualtotal = annualtotal+group.KeywordsPrice
                }
                if(group.MonetizationPackage.PaymentSchedule === 'Monthly'){
                    annualtotal = annualtotal * 12
                }
                return annualtotal
            }).reduce((a, b) => a + b, 0)
        },
        ComputedMonetizationGroups(){
            //console.log('this.SiteGroupsArray',this.SiteGroupsArray)
            return !this.Refreshing ? this.SiteGroupsArray.filter(group => {
                //console.log('group.MonetizationPackage',group.MonetizationPackage)
                return group.MonetizationPackage
            })
            .map(group => {
                let groupobj = Object.assign({},group)
                groupobj.MonetizationPackage = this.GroupMonetizationPackages.find(obj => obj.ID === group.MonetizationPackage.ID)               
                
                let paidkeywordorders = 0
                let keywords = 0
                let freecount = 0
                if(this.GroupKeywordsPackage){                    
                    if(group.KeyWords){
                     keywords = group.KeyWords.length   
                    }                    
                    if(groupobj.MonetizationPackage.KeywordsIncluded){
                        freecount = groupobj.MonetizationPackage.KeywordsIncluded
                    }
                    keywords = keywords-freecount
                    if(keywords > 0){
                        groupobj.KeyWordsToolip = keywords+' Keywords'
                        if(freecount > 0){
                            groupobj.KeyWordsToolip = groupobj.KeyWordsToolip+' over and above the free '+freecount
                            groupobj.KeyWordsToolip = groupobj.KeyWordsToolip+
                            `<ul>`
                            groupobj.KeyWords.map(kw => {
                                groupobj.KeyWordsToolip = groupobj.KeyWordsToolip+
                            `<li>`+kw+`</li>`
                            })
                            groupobj.KeyWordsToolip = groupobj.KeyWordsToolip+
                            `</ul>`
                        }
                        paidkeywordorders = Math.ceil(keywords/this.GroupKeywordsPackage.KeywordOrderQty)
                    }
                    groupobj.KeywordsPrice = paidkeywordorders*this.GroupKeywordsPackage.KeywordOrderPrice
                }
                return groupobj
            })
            .filter(group => {
                if(this.GroupSearch){
                    return group.name.toLowerCase().includes(this.GroupSearch.toLowerCase())
                }
                else{
                    return group
                }
            })
            .filter(group => {
                if(this.LevelFilter && this.LevelFilterValue){
                    return group[this.LevelFilter.FieldName] && group[this.LevelFilter.FieldName].ID === this.LevelFilterValue.ID
                }
                else{
                    return group
                }
            }) : []
        },
        SocialGroupsQuery(){
            return this.$store.state.SiteGroupsQuery
        },
        SiteGroupsArray(){
            return this.$store.state.SiteGroupsArray
        },
        GroupMonetizationPackages(){
            return this.System.GroupMonetizationPackages ? this.System.GroupMonetizationPackages : []
        }
    },
    watch: {
    SiteGroupsArray: {
            handler: function(oldvalue, newvalue) {
              if(oldvalue !== newvalue){                 
                this.RefreshView()                
              }
            },deep: true
        },
    },
    created(){
        //console.log('this.$store.state',this.$store.state,this.SocialGroupsQuery)
        if(this.SocialGroupsQuery){
                 //GroupsArray
                 let vm = this
                  if(this.$store.state.SiteGroupsArray && this.$store.state.SiteGroupsArray.length > 0){
                      this.RefreshView()
                  }
                  else{
                      let length = this.SocialGroupsQuery.length
                    this.SocialGroupsQuery.map((query,queryindex) => {
                     let storepayload = {
                            query: query,
                            arrayname: 'SiteGroupsArray'
                        }
                        //console.log(storepayload,this.$store.state)
                        this.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                            //console.log('result',result,queryindex,length,queryindex-1+2 === length)
                            if(queryindex-1+2 === length){
                               vm.RefreshView() 
                            }
                        })
                    })                    
                  }
                }
                else{
                    //alert('no query')
                }
    },
    methods:{
        RefreshView(){
            //console.log('RefreshView',this.$store.state,this.SiteGroupsArray,this.ComputedMonetizationGroups)
            this.Refreshing = true
                setTimeout(() => {
                    this.Refreshing = false
                }, 10);
        },
        ActivateCatFilter(lvl,value){
            if(value === this.LevelFilterValue){
                this.LevelFilter = ''
                this.LevelFilterValue = ''
            }
            else{
             this.LevelFilter = lvl
            this.LevelFilterValue = value   
            }
            
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },	
    }
}
</script>

<style>

</style>
